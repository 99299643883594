<template>
  <div>
    <div v-if="type == 'add'">
      <a-form-model
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="所属公司">
          <a-select v-model="formInline.mtBcId" class="search-expand-value">
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上级类别">
          <a-tree-select
            v-model="formInline.mtSupId"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="filters.GetMaterial"
            tree-default-expand-all
            :replaceFields="{
              key: 'mtId',
              title: 'mtName',
              value: 'mtId',
              children: 'children',
            }"
          >
          </a-tree-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="类别名称">
          <a-input v-model="formInline.mtName" placeholder=""> </a-input>
        </a-form-model-item> -->

        <a-form-model-item label="类别名称">
          <a-input v-model="formInline.mtName" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div v-if="type == 'edit'">
      <a-form-model
        :model="formInlineEdit"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="所属公司">
          <a-select
            v-model="formInlineEdit.mtBcId"
            class="search-expand-value"
            disabled
          >
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上级类别">
          <a-tree-select
            v-model="formInlineEdit.mtSupId"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="filters.GetMaterial"
            tree-default-expand-all
            :replaceFields="{
              key: 'mtId',
              title: 'mtName',
              value: 'mtId',
              children: 'children',
            }"
          >
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="类别名称">
          <a-input v-model="formInlineEdit.mtName" placeholder=""> </a-input>
        </a-form-model-item>

        <!-- <a-form-model-item label="描述">
          <a-input v-model="formInlineEdit.mtSupId" placeholder=""> </a-input>
        </a-form-model-item> -->
        <a-form-model-item>
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import {
  GetMaterialTypeList,
  AddMaterialType,
  UpdateMaterialType,
} from "@/api/apiJF/template";
export default {
  name: "MaterialCategoriesEdit",
  components: {},
  props: {
    record: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      formInline: {
        mtName: "",
        mtBcId: "",
        mtSupId: "",
      },
      formInlineEdit: {
        mtId: "", //类别id
        mtBcId: "", //分公司id
        mtName: "", //名称
        mtSupId: "", //上级类别id
        mtCreateTime: "", //创建时间
      },
      filters: { GetCompanies: [], GetMaterial: [] },
    };
  },
  created() {
    // 获取分公司信息
    GetCompanies().then((res) => {
      // console.log("GetCompanies", res.data);
      this.filters.GetCompanies = res.data;
    });

    // let mtBcIds = this.record.mtBcIds
    //   ? this.record.mtBcIds
    //   : this.record.mtBcId;
    // this.GetMaterialType(mtBcIds);
    if (this.type == "edit") {
      this.formInlineEdit.mtName = this.record.mtName;
      this.formInlineEdit.mtBcId = this.record.mtBcId;
      this.formInlineEdit.mtId = this.record.mtId;
      this.GetMaterialType(this.formInlineEdit.mtBcId);
      // this.formInlineEdit.mtSupId =
      //   this.record.mtSupId == 0 ? this.record.mtId : this.record.mtSupId;
      this.formInlineEdit.mtSupId = this.record.mtSupId;
      console.log(this.type, this.record, this.formInlineEdit);
    } else {
      this.formInline.mtBcId = this.record.mtBcId ? this.record.mtBcId : "";
      // this.formInline.mtSupId =
      //   this.record.mtSupId == 0 ? this.record.mtId : this.record.mtSupId;
      this.formInline.mtSupId = this.record.mtId ? this.record.mtId : "0";

      // console.log(this.type, this.record, this.formInline);
      // this.formInline.mtName = this.record.mtName;
      let mtBcIds = this.record.mtBcIds
        ? this.record.mtBcIds
        : this.record.mtBcId;
      // this.GetMaterialType(mtBcIds);
      this.filters.GetMaterial = [{ mtId: 0, mtName: "主类目" }];
    }
  },
  mounted() {},
  watch: {
    "formInline.mtBcId": {
      handler(newValue, oldValue) {
        if (newValue != "" && oldValue != "") {
          console.log("1111111", newValue, oldValue);
          // this.GetMaterialType(newValue);
          this.formInline.mtSupId = 0;
        }
        if (newValue != "") {
          this.GetMaterialType(newValue);
        }
      },
    },
  },
  methods: {
    // 获取所有类别名称及id
    GetMaterialType(mtBcIds) {
      let queryParam = {
        mtBcIds: mtBcIds,
      };
      let orderParam = ["mtBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      this.filters.GetMaterial = [{ mtId: 0, mtName: "主类目" }];
      return GetMaterialTypeList(params).then((res) => {
        res.data.forEach((el) => {
          this.filters.GetMaterial.push(el);
        });
        // console.log(" this.filters.GetMaterial", this.filters.GetMaterial);
      });
    },

    handleSubmit(e) {
      // console.log(this.formInline);
      // console.log(this.formInlineEdit);
      // console.log(Object.keys(this.formInline));
      // console.log(Object.keys(this.formInlineEdit));
      if (this.type == "add") {
        // console.log("提交");
        let form = {
          mtName: this.formInline.mtName,
          mtBcId: this.formInline.mtBcId,
          mtSupId: this.formInline.mtSupId != "" ? this.formInline.mtSupId : 0,
        };
        console.log("add", form);
        let addVerify = Object.keys(form);
        const orderParam = this.$order(form, addVerify);
        const params = {
          ...orderParam,
          verify: this.$verify(orderParam, addVerify),
        };
        AddMaterialType(params).then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$message.success("添加成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.type == "edit") {
        // this.formInlineEdit.mtId = this.record.mtId;
        // this.formInlineEdit.mtBcId = this.record.mtBcId;
        this.formInlineEdit.mtCreateTime = this.record.mtCreateTime;
        let params = this.formInlineEdit;
        // let editVerify = Object.keys(this.formInlineEdit);
        // const orderParam = this.$order(this.formInlineEdit, editVerify);
        // const params = {
        //   ...orderParam,
        //   verify: this.$verify(orderParam, editVerify),
        // };
        UpdateMaterialType(params).then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onClose() {
      this.$emit("onClose");
      this.filters.GetMaterial = [];
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  text-align: center;
}
</style>